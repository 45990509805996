<template>
  <div>
    <a-button type="link" size="large" @click="backHome">返回首页</a-button>
    <a-button type="link" size="large" @click="backPrev" v-if="code !== '403'"
      >返回上一页({{ second }} s)</a-button
    >
  </div>
</template>

<script>
import "./error.less";
export default {
  name: "backBtnGroup",
  props: ["code"],
  data() {
    return {
      second: 5,
      timer: null,
    };
  },
  methods: {
    backHome() {
      this.$router.replace({
        path: "/daily_life",
      });
    },
    backPrev() {
      this.$router.go(-1);
    },
  },
  mounted() {
    if (this.code === "403") {
      return;
    } else {
      this.timer = setInterval(() => {
        if (this.second === 0) this.backPrev();
        else this.second--;
      }, 1000);
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
